<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="mb-4">Event Info</h5>
                        <div class="row">
                            <div class="text-center col-xl-5 col-lg-6">
                                <img
                                    v-if="event.imageV || event.imageH"
                                    v-img
                                    :alt="event.name"
                                    :src="event.imageV || event.imageH"
                                    class="mx-auto shadow-lg w-100 border-radius-lg"
                                />
                                <div class="pt-2 mt-4 my-gallery d-flex">
                                    <figure itemprop="associatedMedia" itemscope="" itemtype="http://schema.org/ImageObject">
                                        <img
                                            v-if="event.imageV"
                                            v-img="{
                                                group: 'v-img-gallery'
                                            }"
                                            :alt="event.name"
                                            :src="event.imageV"
                                            class="shadow w-75 min-height-100 max-height-100 border-radius-lg"
                                        />
                                    </figure>
                                    <figure itemprop="associatedMedia" itemscope="" itemtype="http://schema.org/ImageObject">
                                        <img
                                            v-if="event.imageH"
                                            v-img="{
                                                group: 'v-img-gallery'
                                            }"
                                            :alt="event.name"
                                            :src="event.imageH"
                                            class="shadow w-75 min-height-100 max-height-100 border-radius-lg"
                                        />
                                    </figure>
                                </div>

                                <!-- <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
                                    <div class="pswp__bg"></div>
                                    <div class="pswp__scroll-wrap">
                                        <div class="pswp__container">
                                            <div class="pswp__item"></div>
                                            <div class="pswp__item"></div>
                                            <div class="pswp__item"></div>
                                        </div>
                                        <div class="pswp__ui pswp__ui--hidden">
                                            <div class="pswp__top-bar">
                                                <div class="pswp__counter"></div>
                                                <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--close"> Close (Esc) </button>
                                                <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--fs"> Fullscreen </button>
                                                <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--arrow--left"> Prev </button>
                                                <button class="btn btn-white btn-sm pswp__button pswp__button--arrow--right"> Next </button>
                                                <div class="pswp__preloader">
                                                    <div class="pswp__preloader__icn">
                                                        <div class="pswp__preloader__cut">
                                                            <div class="pswp__preloader__donut"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                                                <div class="pswp__share-tooltip"></div>
                                            </div>
                                            <div class="pswp__caption">
                                                <div class="pswp__caption__center"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                            <div class="col-lg-6">
                                <h3 class="mt-4 mt-lg-0">{{ event.name }}</h3>
                                <!-- <div class="rating">
                                    <i class="fas fa-star" aria-hidden="true"></i>
                                    <i class="fas fa-star" aria-hidden="true"></i>
                                    <i class="fas fa-star" aria-hidden="true"></i>
                                    <i class="fas fa-star" aria-hidden="true"></i>
                                    <i class="fas fa-star-half-alt" aria-hidden="true"></i>
                                </div> -->
                                <!-- <br>
                                <h6 class="mt-3 mb-0">Price</h6>
                                <h5>$1,419</h5>
                                <span class="badge badge-success">In Stock</span>
                                <br> -->

                                <label>Description</label>
                                <div v-html="event.description"></div>

                                <!-- <ul>
                                    <li> The most beautiful curves of this swivel stool adds an elegant touch to any environment </li>
                                    <li>Memory swivel seat returns to original seat position</li>
                                    <li> Comfortable integrated layered chair seat cushion design </li>
                                    <li>Fully assembled! No assembly required</li>
                                </ul> -->

                                <div v-if="event?.tickets?.length" class="mt-4 row">
									<hr />
									<h5 class="ms-3">Tickets Info.</h5>
                                    <div class="table table-responsive">
										<table class="table mb-0 align-items-center">
											<thead>
												<tr>
													<th class="text-uppercase text-secondary text-xs font-weight-bolder">Title</th>
													<th class="text-uppercase text-secondary text-xs font-weight-bolder ps-2">Price</th>
													<th class="text-uppercase text-secondary text-xs font-weight-bolder ps-2">Qty</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(ticket, index) in event.tickets" :key="`event-ticket-${index}`">
													<td>
														<p class="mb-0 text-sm text-secondary">{{ ticket.title }}</p>
													</td>
													<td>
														<p class="mb-0 text-sm text-secondary">${{ ticket.price }}</p>
													</td>
													<td>
														<p class="mb-0 text-sm text-secondary">{{ ticket.count }}</p>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

                                </div>
                            </div>
                        </div>

                        <EventTicketsOrder
                            :venue-id="id"
                            :event-id="eventId"
                            :columns="[
                                { key: 'clientName', label: 'Name', sortable: false },
                                { key: 'clientEmail', label: 'Email', sortable: false, class: 'text-center' },
                                { key: 'clientPhoneNumber', label: 'Phone', sortable: false, class: 'text-center' },
                                { key: 'bookingStatus', label: 'Status', sortable: false, class: 'text-center' },
                                { key: 'totalTicketsPrice', label: 'Total', sortable: false, class: 'text-center' },
                                { key: 'guestsCount', label: 'Guests', sortable: false, class: 'text-center' },
                                { key: 'created_at', label: 'Received', sortable: true, class: 'text-center' },
                            ]"
                        />

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EventTicketsOrder from '@/components/venue/EventTicketsOrder'

export default {
    name: 'EventsInfo',

    props: ['id', 'eventId'],

    data() {
        return {}
    },

    computed: {
        ...mapGetters('operations', ['event']),
    },

    components: {
        EventTicketsOrder
    },

    beforeMount () {
        this.getEventInfoAction(this.eventId)
    },

    methods: {
        ...mapActions('operations', [
            'getEventInfoAction'
        ])
    },

}
</script>
